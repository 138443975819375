<template>
  <div class="home">
    <div class="searchNav">
      <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        :loading="loading"
        @click="dialogConfirm"
        >新增</el-button
      >
      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.keyword"
          placeholder="请输入货单编号/签收人"
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      :max-height="height"
      v-loading="loading"
    >
      <!-- <el-table-column prop="CompanyName" label="所属公司" width="250">
      </el-table-column> -->
      <el-table-column prop="BillId" label="编号" width="170px">
      </el-table-column>
      <el-table-column prop="CreateTime" label="日期" width="120">
        <template slot-scope="scope">
          {{
            scope.row.CreateTime ? scope.row.CreateTime.substring(0, 10) : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="CustomerName" label="客户"> </el-table-column>
      <!-- <el-table-column prop="ContactPersonName" label="收货人">
        <template slot-scope="scope">
          {{ scope.row.ContactPersonName }}
          {{
            scope.row.ContactPersonName && scope.row.CustomerPhone ? "/" : ""
          }}
          {{ scope.row.CustomerPhone }}
        </template>
      </el-table-column> -->
      <el-table-column prop="FullName" label="签收人">
        <template slot-scope="scope">
          {{ scope.row.FullName }}
          {{ scope.row.FullName && scope.row.ReceiptPhone ? "/" : "" }}
          {{ scope.row.ReceiptPhone }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="CustomerPhone" label="签收手机号" width="130">
            <template slot-scope="scope">
              {{
                scope.row.CustomerPhone != "暂无" ? scope.row.CustomerPhone : ""
              }}
            </template>
          </el-table-column> -->
      <el-table-column prop="Status" label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-tag class="tableEltag" v-if="scope.row.Status == 0"
            >待签收</el-tag
          >
          <el-tag class="tableEltag" v-if="scope.row.Status == 1" type="success"
            >已签收</el-tag
          >
          <el-tag class="tableEltag" v-if="scope.row.Status == 2" type="danger"
            >已拒收</el-tag
          >
          <el-tag class="tableEltag" v-if="scope.row.Status == 3" type="warning"
            >有差异</el-tag
          >
          <el-tag class="tableEltag" v-if="scope.row.Status == 4" type="danger"
            >已作废</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="标记" width="100" align="center">
        <template slot-scope="scope">
          <el-tag
            class="tableEltag"
            v-for="item in scope.row.Tag ? scope.row.Tag.split(',') : []"
            :key="item"
          >
            {{ item }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-link
            type="primary"
            :underline="false"
            @click="handelOpen(scope.row)"
            >标记
          </el-link>
          <el-link
            class="linkmargin"
            v-if="scope.row.Status == 0"
            type="primary"
            :underline="false"
            @click="openwaybillForm(scope.row)"
            >编辑
          </el-link>
          <el-link
            v-if="scope.row.Status != 4"
            type="primary"
            :underline="false"
            class="linkmargin"
            @click="openNewPage(scope.row)"
            >查看
          </el-link>
          <el-popconfirm
            v-if="scope.row.Status == 3"
            confirm-button-text="确认"
            cancel-button-text="取消"
            cancel-button-type=""
            title="此操作将同意当前回执单差异,确认继续吗?"
            @confirm="differentConfrim(scope.row)"
            ><el-link
              slot="reference"
              type="primary"
              class="linkmargin"
              :underline="false"
              >确认</el-link
            >
          </el-popconfirm>
          <el-link
            v-if="scope.row.Status < 3"
            type="primary"
            :underline="false"
            class="linkmargin"
            @click="handleDownload(scope.row)"
            >下载
          </el-link>
          <el-link
            v-if="scope.row.Status == 0"
            type="danger"
            class="linkmargin"
            :underline="false"
            @click="handleInvalidate(scope.row)"
            >作废
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total,prev, pager, next,sizes"
      :total="pagination.total"
      :current-page="pagination.current"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.pageSizeOptions"
      style="text-align: right; margin-top: 8px; padding: 0"
      @current-change="currentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
    <el-dialog title="选择模板" width="30%" :visible.sync="dialogVisible">
      <el-select
        v-model="templateId"
        placeholder="请选择模板"
        style="width: 100%"
      >
        <el-option
          v-for="(item, index) in templateList"
          :key="item.Id"
          :label="item.TemName"
          :value="index"
        ></el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="添加标记"
      width="30%"
      :visible.sync="inputDialogVisible"
      :close-on-click-modal="false"
    >
      <el-tag
        :key="tag"
        v-for="tag in dynamicTags"
        closable
        :disable-transitions="false"
        @close="handleClose(tag)"
        @click="selectTag(tag)"
      >
        {{ tag }}
      </el-tag>
      <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="small"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      >
      </el-input>
      <el-button v-else class="button-new-tag" size="small" @click="showInput"
        >+ 添加标记</el-button
      >
      <div style="margin-bottom: 10px">已选标记</div>
      <div>
        <el-tag
          :key="tag"
          v-for="tag in selectTags"
          closable
          :disable-transitions="false"
          @close="handleSelectClose(tag)"
          type="success"
        >
          {{ tag }}
        </el-tag>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="inputDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="inputDialogConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <waybillForm ref="waybillForm" :parentObj="{ ...this }" />
  </div>
</template>

<script>
const downloadFile = (url, downloadName = "") => {
  // console.log(url);
  const link = document.createElement("a");
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      link.href = URL.createObjectURL(blob);
      // console.log(link.href)
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
import radioGroup from "@/components/CompanyRadioGroup.vue";
import waybillForm from "./waybillForm.vue";
export default {
  name: "Home",
  components: { radioGroup, waybillForm },
  data() {
    return {
      activeName: "0",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      queryParam: { SupplieId: "全部", Status: "0", Condition: "psq" },
      keyword: "",
      loading: false,
      dialogVisible: false,
      templateId: "",
      templateList: [],
      tabList: [
        { label: "全部", name: "first" },
        { label: "待签收", name: "0" },
        { label: "已签收", name: "1" },
        { label: "差异", name: "3" },
        { label: "拒收", name: "2" },
        { label: "作废", name: "4" },
      ],
      ip: "",
      inputDialogVisible: false,
      inputVisible: "",
      inputValue: "",
      dynamicTags: [],
      selectTags: [],
      rowId: "",
    };
  },
  mounted() {
    // this.getTemplateList()
    this.ip = sessionStorage.getItem("ip");
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    // 标记
    handelOpen(row) {
      this.rowId = row.Id;
      if (row.Tag) this.selectTags = row.Tag.split(",");
      else this.selectTags = [];
      let arr = localStorage.getItem("tagList");
      if (!arr) arr = [];
      else arr = JSON.parse(arr);
      this.dynamicTags = arr;
      this.inputDialogVisible = true;
    },
    // 标记保存
    inputDialogConfirm() {
      if (this.inputVisible) {
        let inputValue = this.inputValue;
        console.log(
          this.dynamicTags.findIndex((item) => item == this.inputValue)
        );
        if (
          inputValue &&
          this.dynamicTags.findIndex((item) => item == this.inputValue) == -1
        ) {
          this.dynamicTags.push(inputValue);
        }
        this.inputValue = "";
      }
      localStorage.setItem("tagList", JSON.stringify(this.dynamicTags));
      if (this.selectTags.length <= 0)
        return this.$message.error("请至少选择一项");
      this.$http
        .post("/BO/BillList/UpdateTag", {
          Id: this.rowId,
          Tag: this.selectTags.join(","),
        })
        .then((res) => {
          if (res.Success) {
            this.$message.success("操作成功");
            this.getDataList();
            this.inputDialogVisible = false;
          } else this.$message.error(res.Msg);
        });
    },
    selectTag(tag) {
      console.log(tag);
      let idx = this.selectTags.findIndex((item) => item == tag);
      if (idx == -1) this.selectTags.push(tag);
      else this.selectTags.splice(idx, 1);
    },
    // 标签删除
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    // 已选标签删除
    handleSelectClose(tag) {
      this.selectTags.splice(this.selectTags.indexOf(tag), 1);
    },
    // 增加标签
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (
        inputValue &&
        this.dynamicTags.findIndex((item) => item == this.inputValue) == -1
      ) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // 编辑
    openwaybillForm(row) {
      this.$http
        .post("/BO/BillList/GetTheBillData", { id: row.Id })
        .then((res) => {
          if (res.Success) {
            console.log(res);
            this.$refs.waybillForm.openForm(res.Data, "1", row.Company, "");
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 同意差异
    differentConfrim(row) {
      this.$http
        .post("/BO/BillList/ConfigSubmit?Id=" + row.Id + "&IP=" + this.ip)
        .then((res) => {
          if (res.Success) {
            this.getDataList();
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 重启
    resetStart(row, type) {
      let url = "";
      if (type == "confirm") url = "/BO/BillList/WebForm";
      else url = "/BO/BillList/Processing";
      this.$http.post(url + "?Id=" + row.Id).then((res) => {
        if (res.Success) {
          this.getDataList();
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 重置
    resetTemplate(row) {
      this.$confirm("此操作将改变当前货单模板, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .post(
            "/BO/BillList/ResetFormTemplate?Id=" +
              row.Id +
              "&CommpayId=" +
              row.Company
          )
          .then((res) => {
            if (res.Success) {
              this.$message.success("操作成功");
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    // 打开单据页面
    openNewPage(row) {
      // let isClient = sessionStorage.getItem("client");
      let url = `${location.href.split("#")[0]}#/SendSMS?prop=${row.Id}`;
      //  if (isClient) {
      //   url = `${location.href.split("#")[0]}#/SendSMSCopy?prop=${row.Id}`;
      // }
      window.open(url, "_blank");
    },
    // 新增电子货单
    async dialogConfirm() {
      // if (!this.templateId) return this.$message.error("请选择模板");
      if (
        !this.queryParam.SupplieId ||
        this.queryParam.SupplieId.split(",").length > 1 ||
        this.queryParam.SupplieId === "全部"
      ) {
        return this.$message.warning("请选择公司后新增");
      }
      let result = await this.$http.post(
        "/Base_Manage/Base_Company/DataIntegrity?CommpayId=" +
          this.queryParam.SupplieId
      );
      if (!result.Success) return this.$message.error(result.Msg);
      this.loading = true;
      this.$http
        .post(
          `/Base/Base_Template/GetCompanyTemp?CompayId=${this.queryParam.SupplieId}`
        )
        .then((res) => {
          if (res.Success) {
            this.$refs.waybillForm.openForm(
              res.Data.Content,
              "",
              this.queryParam.SupplieId,
              res.Data.TemName
            );
            this.dialogVisible = false;
          } else {
            this.$message.error(res.Msg);
          }
          this.loading = false;
        });
      // this.$http
      //   .post('/Base/Base_Template/GetTemplateDefault', {})
      //   .then((res) => {
      //     if (res.Success) {
      //       this.$refs.waybillForm.openForm(res.Data.Content)
      //       this.dialogVisible = false
      //     } else {
      //       this.$message.error(res.Msg)
      //     }
      //     this.loading = false
      //   })
      // this.$refs.waybillForm.openForm(
      //   // this.templateList[this.templateId].Content
      //   this.templateList[this.templateList.length - 1].Content
      // )
      // this.dialogVisible = false;
    },
    // 公司切换
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    // 页签切换
    handleClick(tab, event) {
      this.pagination = {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      };
      this.queryParam.Status = this.activeName + "";
      if (this.activeName == "first") this.queryParam.Status = "";
      this.getDataList();
    },
    // 下载
    handleDownload(scope) {
      this.$http.post("/BO/BillList/GetPDF?id=" + scope.Id).then((res) => {
        if (res.Success) {
          downloadFile(res.Data, scope.BillId);
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 作废
    handleInvalidate(scope) {
      this.$confirm("确认要作废本条单据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .post("/BO/BillList/ToVoid?Billd=" + scope.Id + "&IP=" + this.ip)
          .then((res) => {
            if (res.Success) {
              this.$message.success("操作成功");
              this.getDataList();
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    // 模板列表
    getTemplateList() {
      this.$http.post("/Base/Base_Template/GetDataList", {}).then((res) => {
        if (res.Success) {
          this.templateList = res.Data;
        }
      });
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/BillList/GetBillList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.button-new-tag {
  margin-bottom: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-bottom: 10px;
  // vertical-align: bottom;
}
.tableEltag {
  margin: 0;
}
/deep/ .el-tabs__nav {
  width: 100%;
  background: #fff;
}
/deep/ .el-tab-pane {
  width: 100%;
  background: #fff;
}
/deep/ .el-tabs__item {
  width: 16.6%;
  text-align: center;
  font-weight: bold;
  padding: 0;
}
/deep/ .el-tabs__item:hover {
  background: #ecf5ff;
}
</style>
